import { jsx as _jsx } from "react/jsx-runtime";
import ThemeRtlLayout from './ThemeRtlLayout';
export default function ThemeSettings({ children }) {
    return (
    // <ThemeColorPresets>
    //   <ThemeContrast>
    _jsx(ThemeRtlLayout, { children: children })
    //    </ThemeContrast>
    // </ThemeColorPresets>
    );
}
